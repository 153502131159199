import React, {useRef} from "react";
import {useReactToPrint} from "react-to-print";
import './print.css'

import {Button, Col, Row, Space} from "antd";
import {PrinterOutlined} from "@ant-design/icons";
import PrinterComponent from "./index";
import axios from "axios";
import Helper from "../../../../Helper";
import {baseAPIUrl} from "../../../../Provider/Config/Config";
import * as Endpoint from "../../../../Provider/Config/EndpointUrl";

const Item = ({label, value}) => {
    return (
        <Row gutter="16" className="mb-0">
            <Col span={12}>
                <div className="td-title">{label}</div>
            </Col>
            <Col span={12}>{value}</Col>
        </Row>
    );
};

const _showReport = (fileName, pdf = true) => {
    axios.defaults.headers.common["X-API-KEY"] =
        "e3784eb2-b842-4a31-a204-14bc90bc5856";
    axios.defaults.headers.common["Authorization"] =
        Helper.getlocalStorage("Token");
    axios({
        url: `${baseAPIUrl}${Endpoint.Sample.getBlobPdf}/${fileName}`, //your url
        method: "GET",
        responseType: "blob", // important
    })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (pdf) {
                link.setAttribute("download", `${fileName}.pdf`); //or any other extension
            } else {
                link.setAttribute("download", fileName); //or any other extension
            }
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            Helper.ErrorMessage(error.toString());
        });
};

const Example = ({details, tableData, surveillanceData}) => {
    const refComponent = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => refComponent.current,
    });
    return (
        <>
            {
                surveillanceData?.typeId === 2 ? (
                    <>
                        <div className="analysis-print-wrapper">
                            <div className="tableFilter" style={{marginBottom: "15px"}}>
                                <h2 className="analysis-big-title">Gözetim Detay</h2>
                                <Row gutter="16">
                                    <Col span={12}>
                                        <Item label="İş Bitiş Tarihi:" value={surveillanceData?.dateFinish || ""}/>
                                    </Col>
                                    <Col span={12}>
                                        <Item label="İş Bitimi Hakkında Bilgi:"
                                              value={surveillanceData?.finishDescription || ""}/>
                                    </Col>
                                    {
                                        surveillanceData?.finishFile && (
                                            <Col span={12} className="mt-2">
                                                <Button
                                                    shape="round"
                                                    type="primary"
                                                    onClick={() =>
                                                        _showReport(surveillanceData?.finishFile, false)
                                                    }
                                                >
                                                    İlgili Dosyayı Gör
                                                </Button>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="formButton" style={{marginTop: "0", marginBottom: "16"}}>
                            <Space size={15}>
                                <Button type="primary" icon={<PrinterOutlined/>} onClick={handlePrint}>
                                    Yazdır
                                </Button>
                            </Space>
                        </div>

                        <PrinterComponent
                            ref={refComponent}
                            details={details}
                            tableData={tableData}
                        />
                    </>
                )
            }
        </>
    );
};

export default Example;
