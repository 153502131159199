import React, { Component } from 'react';
import "./footer.css";

import { Layout } from 'antd';
const { Footer } = Layout;

class FooterRn extends Component {
    render() {
        return (
            <Footer style={{ textAlign: 'center' }}>WiseLIMS (v2.14) ©2020 Created by <a href="https://www.flaft.com/tr/" target="_blank">Flaft Software</a></Footer>
        );
    }
}

export default FooterRn;