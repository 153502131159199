import React, {Component} from "react";

import {Link, NavLink} from "react-router-dom";

import PageHeader from "../../../Components/UI/PageHeader";
import Tables from "../../../Components/Table";
import RangeButton from "../../../Components/UI/RangeButton";
import Export from "../../../Components/Export/Export";
import axios from "axios";
import * as Endpoint from "../../../Provider/Config/EndpointUrl";
import {baseAPIUrl} from "../../../Provider/Config/Config";
import {Button, Card, DatePicker, Dropdown, Form, Input, Menu, Modal, Select, Space, Spin, Tag, Upload,} from "antd";

import {DownOutlined, PlusOutlined, UploadOutlined,} from "@ant-design/icons";
import Swal from "sweetalert2";
import DataService from "../../../Provider/Service/DataService";
import Helper from "../../../Helper";
import PrintComponent from "./Print/print";
import moment from "moment";

const {Option} = Select;
const {TextArea} = Input;
const statusFilterArray = [
    {
        value: 0,
        text: "Talep Alındı",
    },
    {
        value: 1,
        text: "Numune Yolda",
    },
    {
        value: 2,
        text: "Numune Ulaştı",
    },
    {
        value: 3,
        text: "Ödeme Bekliyor",
    },
    {
        value: 4,
        text: "Numune Analizde",
    },
    {
        value: 5,
        text: "Raporlanacak",
    },
    {
        value: 6,
        text: "Raporlandı",
    },
    {
        value: 7,
        text: "Muhasebe",
        color: "#9bd006",
    },
    {
        value: 8,
        text: "İşlem Tamamlandı",
    },
];

const statusUrgencyFilterArray = [
    {
        value: "Standart – (Max 24 saat)",
        text: "Standart – (Max 24 saat)",
    },
    {
        value: "Acil – (Max 6 saat)",
        text: "Acil – (Max 6 saat)",
    },
    {
        value: "Acil Değil – (Mesaisiz)",
        text: "Acil Değil – (Mesaisiz)",
    },
];
const typeFilterArray = [
    {
        value: "Analiz",
        text: "Analiz",
    },
    {
        value: "Gözetim",
        text: "Gözetim",
    },
];

// img upload
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isPdf = file.type === 'application/pdf';
    if (!isJpgOrPng && !isPdf) {
        alert('Yalnızca JPG/PNG resim dosyaları veya PDF dosyaları yükleyebilirsiniz!');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
        alert('Dosya boyutu 10MB sınırını aşıyor!');
    }
    return (isJpgOrPng || isPdf) && isLt2M;
}

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            data: [],
            fileList: [],
            sectorId: null,
            loading: true,
            searchText: "",
            searchedColumn: "",
            permId: null,
            workorder_finish: false,
            tableColumns: [
                {
                    title: "Durumu",

                    dataIndex: "statusName",
                    key: "statusName",
                    filters: statusFilterArray,
                    onFilter: (value, record) => {
                        return record.status_id === value + 1;
                    },
                    render: (text, record) => (
                        <Tag color={`#${record.statusColor}`}>{text}</Tag>
                    ),
                },

                {
                    title: "Aciliyet D.",
                    dataIndex: "work_order_urgency",
                    key: "work_order_urgency",
                    filters: statusUrgencyFilterArray,
                    onFilter: (value, record) => {
                        return record.work_order_urgency === value;
                    },
                    render: (text, record) => (
                        <Tag
                            color={
                                record.work_order_urgency == "Acil – (Max 6 saat)"
                                    ? "#da0000"
                                    : record.work_order_urgency == "Acil Değil – (Mesaisiz)"
                                        ? "#2d730b"
                                        : ""
                            }
                        >
                            {text}
                        </Tag>
                    ),
                },

                {
                    title: "Rapor No",
                    dataIndex: "report_no",
                    key: "report_no",
                    ...Helper.getColumnSearchProps(
                        "report_no",
                        this.handleSearch,
                        this.handleReset,
                        "Rapor No"
                    ),
                    sorter: (a, b) => a.report_no.localeCompare(b.report_no),
                },
                {
                    title: "ATF NO",
                    dataIndex: "atf_no",
                    key: "atf_no",
                    ...Helper.getColumnSearchProps(
                        "atf_no",
                        this.handleSearch,
                        this.handleReset,
                        "ATF NO"
                    ),

                    sorter: (a, b) => a.atf_no.localeCompare(b.atf_no),
                },
                {
                    title: "Kayıt No",
                    dataIndex: "registry_no",
                    key: "registry_no",
                    ...Helper.getColumnSearchProps(
                        "registry_no",
                        this.handleSearch,
                        this.handleReset,
                        "Kayıt  No"
                    ),
                    sorter: (a, b) => a.registry_no - b.registry_no,
                },
                {
                    title: "Tip",
                    dataIndex: "work_order_type_name",
                    key: "work_order_type_name",

                    filters: typeFilterArray,
                    onFilter: (value, record) => {
                        return record.work_order_type_name === value;
                    },
                    sorter: (a, b) =>
                        a.work_order_type_name.localeCompare(b.work_order_type_name),
                },
                {
                    title: "Lab",
                    dataIndex: "lab_code",
                    key: "lab_code",
                    ...Helper.getColumnSearchProps(
                        "lab_code",
                        this.handleSearch,
                        this.handleReset,
                        "Lab Kodu"
                    ),
                    sorter: (a, b) => a.lab_code.localeCompare(b.lab_code),
                },
                {
                    title: "Bölge",
                    dataIndex: "region_name",
                    key: "region_name",

                    ...Helper.getColumnSearchProps(
                        "region_name",
                        this.handleSearch,
                        this.handleReset,
                        "Bölge"
                    ),
                    sorter: (a, b) => a.region_name.localeCompare(b.region_name),
                },
                {
                    title: "Kul.Adı",
                    dataIndex: "user_create_name",
                    key: "user_create_name",
                    ...Helper.getColumnSearchProps(
                        "user_create_name",
                        this.handleSearch,
                        this.handleReset,
                        "Lab Kodu"
                    ),
                    sorter: (a, b) =>
                        a.user_create_name.localeCompare(b.user_create_name),
                },
                {
                    title: "İş Kolu",
                    dataIndex: "sector_name",
                    key: "sector_name",
                    ...Helper.getColumnSearchProps(
                        "sector_name",
                        this.handleSearch,
                        this.handleReset,
                        "İş Kolu"
                    ),
                    sorter: (a, b) => a?.sector_name?.localeCompare(b?.sector_name),
                },
                {
                    title: "Numunenin Alındığı Yer",
                    dataIndex: "get_part",
                    key: "get_part",
                    ...Helper.getColumnSearchProps(
                        "get_part",
                        this.handleSearch,
                        this.handleReset,
                        "Numunenin Alındığı Yer İle Arama"
                    ),
                    sorter: (a, b) => a.get_part.localeCompare(b.get_part),
                },
                {
                    title: "Terminal",
                    dataIndex: "get_location",
                    key: "get_location",
                    ...Helper.getColumnSearchProps(
                        "get_location",
                        this.handleSearch,
                        this.handleReset,
                        "Terminal İle Arama"
                    ),
                    sorter: (a, b) => a.get_location.localeCompare(b.get_location),
                },
                {
                    title: "Müşteri",
                    dataIndex: "customer_1_name",
                    key: "customer_1_name",

                    ...Helper.getColumnSearchProps(
                        "customer_1_name",
                        this.handleSearch,
                        this.handleReset,
                        "Müşteri"
                    ),

                    sorter: (a, b) => a.customer_1_name.localeCompare(b.customer_1_name),
                },

                {
                    title: "Operasyon",
                    dataIndex: "operation_name",
                    key: "operation_name",

                    ...Helper.getColumnSearchProps(
                        "operation_name",
                        this.handleSearch,
                        this.handleReset,
                        "Operasyon"
                    ),
                    sorter: (a, b) => a.operation_name.localeCompare(b.operation_name),
                },
                {
                    title: "Ürün",
                    dataIndex: "product_name",
                    key: "product_name",

                    ...Helper.getColumnSearchProps(
                        "product_name",
                        this.handleSearch,
                        this.handleReset,
                        "Ürün"
                    ),
                    sorter: (a, b) => a.product_name.localeCompare(b.product_name),
                },
                {
                    title: "Başlangıç",
                    dataIndex: "date",
                    key: "date",
                    sorter: (a, b) =>
                        new Date(a.date).getTime() - new Date(b.date).getTime(),
                },
                {
                    title: "Bitiş",
                    dataIndex: "date_finish",
                    key: "date_finish",
                    sorter: (a, b) =>
                        new Date(a.date_finish).getTime() -
                        new Date(b.date_finish).getTime(),
                },
                {
                    title: "Detay Bilgi",
                    dataIndex: "get_detail",
                    key: "get_detail",
                    ...Helper.getColumnSearchProps(
                        "get_detail",
                        this.handleSearch,
                        this.handleReset,
                        "Detay Bilgi"
                    ),
                    sorter: (a, b) => a.registry_no - b.registry_no,
                },
                {
                    title: "Seçenekler",
                    dataIndex: "options",
                    key: "options",

                    render: (text, record) => {
                        return (
                            <Space size="middle">
                                <Dropdown overlay={this.Actions(record)}>
                                    <a
                                        className="ant-dropdown-link"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        Seçenekler <DownOutlined/>
                                    </a>
                                </Dropdown>
                            </Space>
                        );
                    },
                },
                // {
                //   title: "Seçenekler",
                //   fixed: "right",
                //   dataIndex: "options",

                //   key: "options",
                //   render: (text, record) => this.Actions(text, record),
                // },
            ],
        };
    }

    _formFinish = async ({
                             workorder_finish_comment,
                             workorder_finish_date,
                             workorder_finish_file,
                         }) => {
        const {fileList, selectWorkorder} = this.state;
        if (!selectWorkorder) {
            this.setState({workorder_finish: false});
            Helper.ErrorMessage("İş emri seçilmedi");

            return;
        }
        let sendFormData = {
            workOrderId: this.state.selectWorkorder,
            comments: workorder_finish_comment,
            date: moment(workorder_finish_date).unix(),
            images: [],
        };
        for (const key in fileList) {
            if (fileList.hasOwnProperty(key)) {
                const element = fileList[key];

                let image;
                try {
                    image = await getBase64(element.originFileObj);
                    image = new Blob([image], {type: element.originFileObj.type});
                } catch (error) {
                    image = new Blob([element.url], {type: "image/jpeg"});
                }

                sendFormData.images.push({
                    image,
                    name: element.name,
                });
            }
        }
        DataService.WorkOrder.workFinished(sendFormData)
            .then((result) => {
                if (!result.status) {
                    Helper.ErrorMessage(result.message);
                } else {
                    this.setState({
                        workorder_finish: false,
                        selectWorkorder: undefined,
                    });
                    Helper.SuccessMessage(result.message);
                }
                console.log("result", result);
                this.getData();
            })
            .catch((error) => console.log("workFinished", error));
        // WorkOrderAdd ' de ornegi var.

    };

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({searchText: ""});
    };

    componentDidMount() {
        const dates = [moment().add(-2, "week"), moment().add(2, "week")];

        this.getData(dates);
    }

    getData = async (
        date = [moment().add(-2, "week"), moment().add(2, "week")]
    ) => {
        this.setState({loading: true});
        try {
            const result = await DataService.WorkOrder.SelectAll(
                0,
                0,
                moment(date[0]).unix(),
                moment(date[1]).unix()
            );
            const responseUser = await DataService.User.Profile();
            if (result.status && responseUser.status) {
                const {data} = result.response;

                let workorders = [];
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        const element = data[key];
                        workorders.push({
                            ...element,
                            id: key,
                        });
                    }
                }
                this.setState({
                    data: workorders,
                    permId: responseUser?.data?.perm_id || null,
                    loading: false,
                });
            } else {
                this.setState({
                    loading: false,
                });
            }
        } catch (error) {
            Helper.ErrorMessage(error);
        }
    };

    _deleteClick = ({report_no}) => {
        Swal.fire({
            title: "Silmek istediğinize emin misiniz?",
            text: `${report_no} Rapor numaralı iş emrini silmek üzeresiniz.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Evet",
            cancelButtonText: "Hayır",
        }).then((result) => {
            if (result.value) {
                this.deleted(report_no);
            }
        });
    };
    deleted = (report_no) => {
        DataService.Sample.remove(report_no)
            .then((result) => {
                Helper.SuccessMessage(result.message);
                if (result.status) {
                    Helper.SuccessMessage(result.message);
                    const dates = [moment().add(-1, "month"), moment()];
                    this.getData(dates);
                } else {
                    Helper.ErrorMessage(result.message);
                    this.setState({loading: false});
                }
            })
            .catch((error) => {
                Helper.ErrorMessage("Bir hata meydana geldi");
            });
    };
    _showReport = (fileName, pdf = true) => {
        axios.defaults.headers.common["X-API-KEY"] =
            "e3784eb2-b842-4a31-a204-14bc90bc5856";
        axios.defaults.headers.common["Authorization"] =
            Helper.getlocalStorage("Token");
        axios({
            url: `${baseAPIUrl}${Endpoint.Sample.getBlobPdf}/${fileName}`, //your url
            method: "GET",
            responseType: "blob", // important
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                if (pdf) {
                    link.setAttribute("download", `${fileName}.pdf`); //or any other extension
                } else {
                    link.setAttribute("download", fileName); //or any other extension
                }
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                Helper.ErrorMessage(error.toString());
            });
    };
    followWorkOrder = ({work_order_id}) => {
        DataService.WorkOrder.Follow(work_order_id)
            .then((result) => {
                Helper.SuccessMessage(result.message);
                if (result.status) {
                    Helper.SuccessMessage(result.message);
                    const dates = [moment().add(-1, "month"), moment()];
                    this.getData(dates);
                } else {
                    Helper.ErrorMessage(result.message);
                }
            })
            .catch((error) => {
                Helper.ErrorMessage("Bir hata meydana geldi");
            });
    };
    Actions = (record) => {
        const visibleChase = record?.chase == 0 || record?.chase == 1;

        return (
            <Menu>
                <Menu.Item key="0">
                    <Link
                        activeClassName="nav-active"
                        to={`/sample/work-orders/edit/${record.report_no}`}
                    >
                        <Button
                            shape="round"
                            type="primary"
                            disabled={record?.status_id >= 5}
                        >
                            Düzenle
                        </Button>
                    </Link>
                </Menu.Item>
                <Menu.Item key="1">
                    <Button
                        shape="round"
                        type="primary"
                        onClick={() => this.renderDetailsWorkOrders(record)}
                    >
                        Detay
                    </Button>
                </Menu.Item>
                {visibleChase && (
                    <Menu.Item key="2">
                        <Button
                            shape="round"
                            type="primary"
                            onClick={() => this.followWorkOrder(record)}
                        >
                            {record?.chase == 0 ? "Takip Et" : "Takibi Bırak"}
                        </Button>
                    </Menu.Item>
                )}
                {(record.work_order_type_id == 2 && record.status_id != 9) && (
                    <Menu.Item key="5">
                        <Button
                            shape="round"
                            type="primary"
                            // disabled={record?.status_id >= 5}
                            onClick={() =>
                                this.setState({
                                    workorder_finish: true,
                                    selectWorkorder: record.work_order_id,
                                })
                            }
                        >
                            İşi Tamamla
                        </Button>
                    </Menu.Item>
                )}
                {(record.work_order_type_id == 2 && record.finish_file) && (
                    <Menu.Item key="5">
                        <Button
                            shape="round"
                            type="primary"
                            onClick={() =>
                                record?.finish_file ? this._showReport(record?.finish_file, false) : 0
                            }
                        >
                            Raporu Gör
                        </Button>
                    </Menu.Item>
                )}
                {record.status_id == 7 && (
                    <Menu.Item key="3">
                        <Button
                            shape="round"
                            type="primary"
                            onClick={() =>
                                record?.fileName ? this._showReport(record?.fileName) : 0
                            }
                        >
                            Raporu Gör
                        </Button>
                    </Menu.Item>
                )}
                {record?.status_id == 7 && (
                    <Menu.Item key="4">
                        <Button
                            shape="round"
                            type="primary"
                            onClick={() =>
                                record?.fileName
                                    ? this.sendReportMailMessage({
                                        workOrderId: record?.work_order_id,
                                        fileName: record?.fileName,
                                    })
                                    : 0
                            }
                        >
                            Mail Gönder
                        </Button>
                    </Menu.Item>
                )}
                {this.state?.permId == "bba0328e0b5726131ff55fe94c1edff4" && (
                    <Menu.Item key="6">
                        <Button type="link" onClick={() => this._deleteClick(record)}>
                            Sil
                        </Button>
                    </Menu.Item>
                )}
            </Menu>
        );
    };
    sendReportMailMessage = ({workOrderId, fileName}) => {
        Helper.alertMessage({
            title: "Mail Gönder",
            message: "Raporu  mail olarak göndermek istiyor musunuz?",

            onDone: () => this.senReportMail({workOrderId, fileName}),
        });
    };
    senReportMail = ({workOrderId, fileName}) => {
        this.setState({loading: true});
        DataService.Sample.sendReportMail({workOrderId, fileName})
            .then((result) => {
                this.setState({loading: false});
                if (result.status) {
                    Helper.SuccessMessage(result.message);
                    this.getData();
                } else {
                    Helper.ErrorMessage(result.message);
                    this.getData();
                }
            })
            .catch((error) => {
                this.setState({loading: false});
                Helper.ErrorMessage(error.toString());

            });
    };
    renderDetailsWorkOrders = async ({work_order_id}) => {
        try {
            this.setState({loading: true});
            const result = await DataService.WorkOrder.Details(work_order_id);
            if (result.status) {
                this.setState({
                    loading: false,
                    printer: true,
                    printed: {
                        details: result?.data?.sampleInfo || {},
                        data: result?.data?.sample || [],
                        surveillanceData: result?.data?.surveillanceData || [],
                    },
                });
            } else {
                this.setState({loading: false});
                Helper.ErrorMessage(result?.message || "Bir hata meydana geldi.");
            }
        } catch (error) {
            this.setState({loading: false});
            console.log("error", error);
            Helper.ErrorMessage("Sistemsel bir hata meydana geldi");
        }
    };
    _tableHandleChange = (pagintions, filters, sorter, extra) => {
        this.setState({
            filteredInfo: filters,
            exportData: extra.currentDataSource,
        });
    };
    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle:
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
        });
    };
    handleChange = ({fileList}) => {
        this.setState({fileList});
    };

    render() {
        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title="İş Listesi"/>

                    <div className="pageHeaderOptions">
                        <Space>
                            <Export
                                data={this.state?.exportData || this.state.data}
                                columns={[...this.state.tableColumns]}
                                tableName={"WorkOrders"}
                                dates={["tarih"]}
                            />

                            <NavLink
                                activeClassName="nav-active"
                                exact
                                to="/sample/work-orders/add"
                            >
                                <Button className="btn-add" shape="round">
                                    <PlusOutlined/>
                                    Yeni İş Emri Ekle
                                </Button>
                            </NavLink>
                        </Space>

                        <Modal
                            title="Yeni İş Emri Ekle"
                            visible={this.state.visible}
                            onCancel={this.handleCancel}
                            footer=""
                        ></Modal>
                    </div>
                </div>
                <Spin spinning={this.state.loading}>
                    <Card bordered={false} className="cardTable">
                        <RangeButton onClick={this.getData}/>
                        <Tables
                            columns={this.state.tableColumns}
                            onChange={this._tableHandleChange}
                            dataSource={this.state.data}
                            pagination={{
                                defaultPageSize: 1,
                            }}
                            onRow={this.state.data.id} // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
                            rowKey="id" // Unique olarak tablo sıralaması için önemli şimdilik random değer. Daha sonra sector id olarak değiştircem. şu an boş geliyor
                        />
                    </Card>
                </Spin>
                <Modal
                    title="Detay"
                    visible={this.state?.printer || false}
                    onOk={() => this.setState({printer: false})}
                    onCancel={() => this.setState({printer: false})}
                    width={1200}
                    footer=""
                >
                    <PrintComponent
                        ref={(refs) => (this.printerRef = refs)}
                        details={this.state.printed?.details || {}}
                        tableData={this.state.printed?.data || []}
                        surveillanceData={this.state.printed?.surveillanceData || []}
                    />
                </Modal>

                <Modal
                    title="İşi Tamamla"
                    visible={this.state?.workorder_finish || false}
                    onOk={() =>
                        this.setState({workorder_finish: false, work_order_id: undefined})
                    }
                    onCancel={() =>
                        this.setState({workorder_finish: false, work_order_id: undefined})
                    }
                    footer=""
                >
                    <Form
                        layout="vertical"
                        hideRequiredMark
                        onFinish={this._formFinish}
                        ref={this.formRef}
                    >
                        <Form.Item
                            name="workorder_finish_date"
                            label="Tarih"
                            rules={[
                                {
                                    required: true,
                                    message: "Lütfen bu alanı boş bırakmayın",
                                },
                            ]}
                        >
                            <DatePicker
                                style={{width: "100%"}}
                                placeholder="Tarih Seçiniz"
                            />
                        </Form.Item>
                        <Form.Item name="workorder_finish_file" label="İlgili Dosya">
                            <Upload
                                //  listType="picture"
                                maxCount={1}
                                fileList={this.state.fileList}
                                //  onPreview={this.handlePreview}
                                onChange={this.handleChange}
                                beforeUpload={beforeUpload}
                            >
                                <Button icon={<UploadOutlined/>}>Dosya Seçiniz...</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            name="workorder_finish_comment"
                            label="İş Bitimi Hakkında Bilgi"
                            rules={[
                                {
                                    message: "Lütfen bu alanı boş bırakmayın",
                                    type: "string",
                                },
                            ]}
                        >
                            <TextArea rows={4} placeholder=""/>
                        </Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.btnloading}
                        >
                            <PlusOutlined/> İşi Bitir
                        </Button>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default index;
